<template>
    <div class="contact">
        <div class="ragdoll-container">
            <div class="contact-inner">
                <div class="contact-left">
                    <h1>Get in touch</h1>

                    <p>
                        Call us on 01179 102800
                    </p>

                    <form class="contact-form" v-if="!submitted" @submit.prevent="submitForm">
                        <input v-model="name" type="text" placeholder="Name">
                        <input v-model="email" type="email" placeholder="Email">
                        <textarea v-model="message" rows="3" placeholder="Message"></textarea>

                        <button @click.prevent="submitForm" type="submit" class="action-btn btn-next">Send</button>
                    </form>

                    <p v-if="submitted">
                        Thanks for getting in touch. We'll get back to you as soon as possible.
                    </p>

                    <div class="h-line"></div>

                    <div class="button-wrapper mt-5 mb-5">
                        <a href="https://emea.focusvision.com/survey/selfserve/2ad8/240803?list=1"
                            class="action-btn btn-next">Want to take part in research? <span class="fill-out-survey">(Fill out our opt in survey)</span></a>
                    </div>
                    <div>
                        <a href="/careers" class="action-btn btn-next">Career opportunities</a>
                    </div>
                    <div class="socials mt-5">
                        <a href="https://www.linkedin.com/company/ragdoll-research-&-planning/about/?viewAsMember=true">
                            <img src="/wp-content/themes/timber-starter-theme/assets/img/social/linkedin.png"
                                alt="LinkedIn">
                        </a>
                        <a href="https://twitter.com/ragdollresearch?lang=en">
                            <img src="/wp-content/themes/timber-starter-theme/assets/img/social/twitter.svg" alt="Twitter">
                        </a>
                    </div>
                </div>
                <div class="contact-right">
                    <GoogleMap api-key="AIzaSyDA_On90-YbH5DNeywP8-H5NB3wkrvGI6U" style="width: 100%; height: 100%;"
                    :options="{scrollwheel: false}" :scrollwheel="false"
                        :center="center" :disable-default-ui="true" :styles="styles" :zoom="16">
                        <Marker :options="{ position: marker }" />
                        <div class="map-overlay">
                            <p class="top">
                                82/84 Queens Rd, <br>
                                Bristol, BS8 1QU
                            </p>
                            <a href="https://www.google.com/maps/dir//Ragdoll+Research+%26+Planning+Ltd,+Avon,+82%2F84+Queens+Rd,+Bristol+BS8+1QU/@51.4574289,-2.612909,17z/data=!4m17!1m7!3m6!1s0x48718ddb2668c26f:0xe00e6f05f6105051!2sRagdoll+Research+%26+Planning+Ltd!8m2!3d51.457429!4d-2.6080381!16s%2Fg%2F1t_hyflg!4m8!1m0!1m5!1m1!1s0x48718ddb2668c26f:0xe00e6f05f6105051!2m2!1d-2.6081315!2d51.4573999!3e3?entry=ttu"
                                class="action-btn btn-next">Directions</a>
                            <p class="bottom">
                                <b>What 3 Words:</b> <br>
                                worker.cloud.noble
                            </p>
                        </div>
                    </GoogleMap>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";
import axios from 'axios';

export default {
    components: { GoogleMap, Marker },
    setup() {
        const center = { lat: 51.457229154469715, lng: -2.611331459367834 };
        const marker = { lat: 51.457389209311096, lng: -2.6081364192154037 };

        return { center, marker };
    },
    data() {
        return {
            styles: [
                {
                    featureType: "all",
                    elementType: "all",
                    stylers: [
                        { saturation: -100 }
                    ]
                }
            ],

            contactFormId: window.CONTACT_FORM_ID,

            name: '',
            email: '',
            message: '',
            isSaving: false,
            submitted: false
        }
    },
    created() {
        this.contactFormId = window.CONTACT_FORM_ID;
    },
    methods: {
        submitForm() {
            let vm = this;
            vm.isSaving = true;

            const formData = new FormData();
            formData.append('your-name', vm.name);
            formData.append('your-email', vm.email);
            formData.append('your-message', vm.message);

            axios.post('/wp-json/contact-form-7/v1/contact-forms/' + this.contactFormId + '/feedback', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(r => {
                vm.isSaving = false;
                vm.submitted = true;

                // Check datalayer is defined
                if (typeof window.dataLayer === 'undefined') {
                    return;
                }

                window.dataLayer.push({
                    event: 'formSubmission',
                    formId: this.contactFormId,
                    formName: 'Contact form',
                    formType: 'Contact form',
                    formSubmission: 'Success'
                });
            }).catch(e => {
                console.log(e);
                alert('Error sending message. Please try again.');
                vm.isSaving = false;
            });
        }
    }
}
</script>

<style scoped lang="scss"></style>