<template>
    <div class="careers">
        <div class="ragdoll-container">
            <div class="careers-inner">
                <div class="careers-left">
                    <h1>Join us</h1>

                    <!-- <p class="subtitle">
                        We’re always looking for passionate people to join our team.
                    </p> -->

                    <div v-if="postContent" v-html="postContent"></div>

                    <form class="contact-form" v-if="!submitted" @submit.prevent="submitForm">
                        <input type="text" placeholder="Name">
                        <input type="email" placeholder="Email">
                        <textarea rows="3" placeholder="Message"></textarea>

                        <button type="submit" class="action-btn btn-next">Send</button>
                    </form>

                    <p v-if="submitted">
                        Thanks for getting in touch. We'll get back to you as soon as possible.
                    </p>
                </div>
                <div class="careers-right">

                    <div class="row">
                        <!-- Column -->
                        <div v-for="(career, i) in careers" :key="`career-${i}`" class="column">
                            <!-- Role -->
                            <div class="role">
                                <div class="top">
                                    <div class="title">
                                        {{ career.custom.title }}
                                    </div>
                                    <p class="description">
                                        {{ career.custom.short_description }}
                                    </p>
                                    <p class="application-deadline">
                                        {{ career.custom.application_deadline }}
                                    </p>
                                </div>
                                <div class="bottom">
                                    <a :href="`/career/${career.slug}`" class="action-btn btn-next">Find Out More</a>
                                </div>
                            </div>
                            <!-- / Role -->
                        </div>
                        <!-- / Column -->
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            careers: window.careers,
            post: window.post,

            contactFormId: window.CONTACT_FORM_ID,

            name: '',
            email: '',
            message: '',
            isSaving: false,
            submitted: false
        }
    },

    components: {},

    mounted() {
        this.careers = window.careers;
        this.post = window.post;
    },

    computed: {
        postContent() {
            if (this.post && this.post.post_content) {
                return this.post.post_content;
            }
            return null;
        }
    },

    methods: {
        submitForm() {
            let vm = this;
            vm.isSaving = true;

            const formData = new FormData();
            formData.append('your-name', vm.name);
            formData.append('your-email', vm.email);
            formData.append('your-message', vm.message);

            axios.post('/wp-json/contact-form-7/v1/contact-forms/' + this.contactFormId + '/feedback', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(r => {
                vm.isSaving = false;
                vm.submitted = true;

                // Check datalayer is defined
                if (typeof window.dataLayer === 'undefined') {
                    return;
                }

                window.dataLayer.push({
                    event: 'formSubmission',
                    formId: this.contactFormId,
                    formName: 'Contact form',
                    formType: 'Contact form',
                    formSubmission: 'Success'
                });
            }).catch(e => {
                console.log(e);
                alert('Error sending message. Please try again.');
                vm.isSaving = false;
            });
        }
    }
}
</script>

<style scoped lang="scss"></style>